import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import config from '../config/config';

const TuTiempo = () => {
  const [imageList, setImageList] = useState([]);
  const navigate = useNavigate();

  const formatDate = (date) => {
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatName = (name) => {
    return name.toLowerCase().replace(/\b\w/g, (letter) => letter.toUpperCase());
  };

  const handleClick = async (imageName, imageUrl) => {
    try {
      localStorage.setItem('imageName', imageName);
      localStorage.setItem('imageUrl', imageUrl); // Almacenar también la URL de la imagen
      navigate(`/${imageName}`);
    } catch (error) {
      console.error('Error al guardar el imageName y imageUrl:', error);
    }
  };

  useEffect(() => {
    fetch(`${config.API_URL}/imagenes/get`)
      .then(res => res.json())
      .then(data => {
        const today = new Date();
        const filteredImages = data
          .map(image => ({
            ...image,
            createdAt: new Date(image.createdAt),
          }))
          .filter(image => image.createdAt <= today)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Ordenar por fecha descendente

        // Convertir imágenes binarios a URLs
        const imagesWithUrls = filteredImages.map(image => {
          const blob = new Blob([new Uint8Array(image.datos.data)], { type: image.tipo });
          const imageUrl = URL.createObjectURL(blob);
          return {
            ...image,
            imageUrl
          };
        });

        setImageList(imagesWithUrls);
      })
      .catch(err => {
        console.error('Error al cargar los datos:', err);
      });
  }, []);

  return (
    <Container maxWidth="lg">
      <Typography variant='h3' color="white" align='center'>Clasificaciones</Typography>

      <Grid container spacing={5} style={{ marginTop: 1 }} align='center'>
        {imageList.map((image, index) => {
          const nombreFormateado = formatName(image.nombre.replace(/_/g, ' '));

          return (
            <Grid item xs={12} sm={4} key={index}>
              <Card
                color="danger"
                variant='outlined'
                style={{ padding: "10px", background: "#000000", color: "white" }}
                sx={{
                  maxWidth: 300,
                  transition: "0.2s",
                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                  borderRadius: 3,
                  "&:hover": {
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardActionArea onClick={() => handleClick(image.nombre, image.imageUrl)}>
                  <CardMedia component="img" src={image.imageUrl} style={{ height: 300, objectFit: 'fill' }} />
                  <CardContent>
                    <Typography variant='h5' align='left'>{nombreFormateado}</Typography>
                    <Typography component="p" variant='body2' align='left'>Fecha: {formatDate(new Date(image.createdAt))}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default TuTiempo;
/** */