import React, {useState} from 'react'
import { NavLink } from 'react-router-dom'
import CreateTable from './CreateTable'
import UpExcel from './UpExcel'
import UpLoad from './UpLoad'

const Menu = () => {

const [create, setCreate] = useState('');
const [excel, setExcel] = useState('');
const [img, setImg] = useState('');

function cerrarSecion(){
    document.getElementById('caja_menu').style.display = 'none';
    document.getElementById('form_login').style.display = 'block';
    document.getElementById('txtusu').value = '';
    document.getElementById('txtpas').value = '';
    document.getElementById('txtusu').focus();
}

function op_crear(){
    setCreate('1');
    setExcel('0');
    setImg('0');
}

function op_excel(){
    setCreate('0');
    setExcel('1');
    setImg('0');
}

function op_img(){
    setCreate('0');
    setExcel('0');
    setImg('1');
}

  return (
    <>
        <div id="caja_menu" style={{textAlign:"left"}}>

<strong className="h3">
  Usuario Esfuerzo Deportivo: 
</strong>
          
<nav className="navbar navbar-expand-lg navbar-dark bg-dark" style={{marginTop:20}}>
  <div className="container-fluid">
    
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div className="navbar-nav">
        <NavLink to="" className="nav-link  h5  text-center" onClick={op_crear} >Agregar carrera</NavLink>
        <NavLink to="" className="nav-link  h5  text-center" onClick={op_excel} >Subir Excel</NavLink>
        <NavLink to="" className="nav-link  h5  text-center" onClick={op_img} >Subir Imagen</NavLink>
        <a className="nav-link  h5  text-center"  style={{color:"blue"}}  href=" " onClick={cerrarSecion} >Cerrar Sesión</a>
      </div>
    </div>
  </div>
</nav>
</div>

    { create === '1' && <CreateTable/>}
    { excel === '1' && <UpExcel/>}
    { img === '1' && <UpLoad/>}
</>
  )
}

export default Menu
